<template>
<div class="action-cell d-flex align-end">
    <div class="d-flex" :class="alignment">
        <div :class="{'ml-2' : header.buttons.length > 1 && index === (header.buttons.length - 1)}" v-for="(button, index) in header.buttons" :key="index">
            <template v-if="button.icon">
                <v-btn @click.stop="emit(button)" :color="button.color ? button.color : 'grey-darken'" icon>
                    <v-icon small>{{button.icon}}</v-icon>
                </v-btn>
            </template>

            <template v-else-if="button.component">
                <template v-if="button.component == 'switch'">
                    <v-switch class="ma-0" v-model="item[header.value]" :disabled="item.switching" hide-details inset @click.stop @change="emit(button)"></v-switch>
                </template>
            </template>

            <template v-else>
                —
            </template>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'action-cell',

    props: {
        item: [Object, String],
        header: Object
    },

    computed: {
        alignment() {
            let classes = "";

            switch (this.header.align) {
                case 'left':
                    classes = "mr-auto";
                    break;
                case 'right':
                    classes = "ml-auto";
                    break;
                case 'center':
                    classes = "mx-auto";
                    break;
                default:
                    classes = "mr-auto";
            }

            return classes;
        }
    },

    methods: {
        emit(item) {
            this.$emit('function', item.function, this.item);
        }
    }
}
</script>

<style lang="scss">
.action-cell {
    .v-input--selection-controls__input {
        margin: 0;
    }
}
</style>
