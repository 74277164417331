<template>
    <div>
        <p class="x-small--text text-uppercase">
            <b>{{ current.title }}</b>
        </p>
        <v-text-field
            v-model="dateLabel"
            v-mask="range ? '##/##/#### ~ ##/##/####' : '##/##/####'"
            :hide-details="hideDetails"
            @input="setDateInput"
            dense
            filled
            solo
            flat
            placeholder="00/00/0000"
            persistent-placeholder
            :required="current.minimum > 0"
            :rules="rules"
            :disabled="loading"
        >
            <template v-slot:prepend-inner>
                <v-dialog :ref="`dialog-${current.key}`" v-model="current.active" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon text small v-bind="attrs" v-on="on" color="primary">
                            <v-icon>mdi-calendar-month-outline</v-icon>
                        </v-btn>
                    </template>

                    <v-date-picker :range="range" color="primary" header-color="primary" v-model="date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn class="white--text" elevation="0" color="error" text @click="current.active = false"
                            >Cancel</v-btn
                        >
                        <v-btn
                            class="white--text"
                            elevation="0"
                            color="primary"
                            text
                            @click="setDatePicker(date, current.key)"
                            >OK</v-btn
                        >
                    </v-date-picker>
                </v-dialog>
            </template>
        </v-text-field>
    </div>
</template>

<script>
import moment from "moment";

///// If there is no date (invalid date), can be fixed by typing it in the clicking on icon

export default {
    name: "date-picker",

    props: {
        value: [String, Object, Number, Array],
        selected: Object,
        disabled: Boolean,
        loading: Boolean,
        hideDetails: Boolean,
        range: Boolean,
        disableRules: Boolean,
    },

    data() {
        var date = "";
        if (this.range) date = [];

        return {
            current: this.selected,
            date: date,
            dateLabel: "",
        };
    },

    watch: {
        value(val) {
            this.init(val);
        },
    },

    computed: {
        rules() {
            let model = this.dateLabel;
            let current = this.current;

            // if (current.rules) return current.rules;

            if (this.disableRules) return [];

            if (current.minimum >= 1) {
                if ((model && model.length && model.length < current.minimum) || (model && !model.length)) {
                    return [(v) => !!v || `Minimum ${this.getRule(current.minimum)} required`];
                }

                if (current.maximum == 1) {
                    return [(v) => !!v || "This field is required"];
                }
            }

            if (current.maximum >= 1) {
                if (model && model.length && model.length > current.maximum) {
                    return [(v) => !!v || `Maximum ${this.getRule(current.maximum)} required`];
                }
            }

            return [];
        },
    },

    methods: {
        init(val) {
            var date = "";
            if (this.range) date = [];

            this.date = date;
            this.dateLabel = "";

            if (!this.range) {
                if (!val) return;
                this.date = this.parseDate(this.value);
                if (!this.date) return (this.dateLabel = "");
                const [year, month, day] = this.date.split("-");
                this.dateLabel = `${day}/${month}/${year}`;
            } else {
                if (!val.length) return;
                this.date = this.parseRange(this.value);
                if (!this.date.length) return (this.dateLabel = "");

                let newDate = [];

                this.date.forEach((date) => {
                    const [year, month, day] = date.split("-");
                    newDate.push(`${day}/${month}/${year}`);
                });

                this.dateLabel = newDate[0] + " ~ " + newDate[1];
            }
        },
        setDatePicker(val, key) {
            if (val) {
                if (this.range) {
                    this.$emit("input", this.parseRange(val, true));
                } else {
                    this.$emit("input", this.parseDate(val, true));
                }
            }

            var dialog = `dialog-${key}`;
            this.$refs[dialog].save(this.model);

            const [year, month, day] = val.split("-");
            this.dateLabel = `${day}/${month}/${year}`;
        },
        setDateInput(val) {
            if (!val) return this.$emit("input", "");

            if (this.range) {
                if (this.validateRange(val)) {
                    let values = this.validateRange(val);
                    if (values && values.length) this.$emit("input", this.parseRange(values, true));
                    // this.dateMenu = false;
                }
            } else {
                if (this.validateDate(val)) {
                    const [day, month, year] = val.split("/");
                    let date = `${year}-${month}-${day}`;
                    if (date) this.$emit("input", this.parseDate(date, true));
                    // this.dateMenu = false;
                }
            }
        },
        validateDate(value) {
            // First check for the pattern
            if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) return false;

            // Parse the date parts to integers
            var parts = value.split("/");
            var day = parseInt(parts[0], 10);
            var month = parseInt(parts[1], 10);
            var year = parseInt(parts[2], 10);

            // Check the ranges of month and year
            if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

            var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            // Adjust for leap years
            if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

            // Check the range of the day
            return day > 0 && day <= monthLength[month - 1];
        },
        validateRange(value) {
            if (!value.includes(" ~ ")) return false;

            let values = value.split(" ~ ");

            // First check for the pattern
            if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(values[0]) || !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(values[1]))
                return false;

            let matches = [];

            values.forEach((value) => {
                // Parse the date parts to integers
                var parts = value.split("/");
                var day = parseInt(parts[0], 10);
                var month = parseInt(parts[1], 10);
                var year = parseInt(parts[2], 10);

                // Check the ranges of month and year
                if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

                var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

                // Adjust for leap years
                if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

                // Check the range of the day
                if (day > 0 && day <= monthLength[month - 1]) {
                    matches.push(`${month}-${day}-${year}`);
                }
            });

            if (matches.length == values.length) return matches;

            return false;
        },
        parseDate(dateString, toISO) {
            if (toISO) return new Date(dateString || null).toISOString();
            let thisDate = new Date(dateString || null).toLocaleString(["en-US"], {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            const [month, day, year] = thisDate.split("/");
            return `${year}-${month}-${day}`;
        },
        parseRange(dateArray, toISO) {
            if (toISO)
                return [new Date(dateArray[0] || null).toISOString(), new Date(dateArray[1] || null).toISOString()];
            let options = { day: "2-digit", month: "2-digit", year: "numeric" };
            let thisDate = [
                new Date(dateArray[0] || null).toLocaleString(["en-US"], options),
                new Date(dateArray[1] || null).toLocaleString(["en-US"], options),
            ];

            let newDate = [];

            thisDate.forEach((date) => {
                const [month, day, year] = date.split("/");
                newDate.push(`${year}-${month}-${day}`);
            });

            return newDate;
        },
        getRule(number) {
            if (number != 1) {
                return number + " items are";
            } else {
                return number + " item is";
            }
        },
    },

    mounted() {
        if (this.value) this.init(this.value);
    },
};
</script>

<style lang="scss"></style>
