<template>
    <div class="d-flex">
        <template v-if="isArray">
            <div class="status-cell" :class="{ 'mr-1': index != value.length - 1 }" v-for="(item, index) in value" :key="index" :content="item" v-if="value && value.length">
                <span class="inline-tag">
                    {{ item.title || item }}
                </span>
            </div>
        </template>

        <template v-else-if="value && value._id">
            <div class="status-cell">
                <span class="inline-tag">
                    {{ value.title }}
                </span>
            </div>
        </template>

        <template v-else-if="value && value.length">
            <div class="status-cell">
                <span class="inline-tag">
                    {{ value }}
                </span>
            </div>
        </template>

        <template v-else-if="value > 0">
            <div class="status-cell">
                <span class="inline-tag">
                    {{ value }}
                </span>
            </div>
        </template>

        <template v-else>
            <span class="dark-grey--text">—</span>
        </template>
    </div>
</template>

<script>
// Packages
import _ from "lodash";

export default {
    name: "tag-cell",

    props: {
        item: [Object, String],
        header: Object,
    },

    computed: {
        value() {
            let value = this.item;
            let header = this.header;

            if (header.parent) value = value[header.parent];
            value = value[header.value];

            return value;
        },
        isArray() {
            return _.isArray(this.value);
        },
    },
};
</script>

<style lang="scss"></style>
