<template>
    <div class="app-table-filters">
        <template v-if="!model.length">
            <v-card class="background pa-2" flat>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <p class="muted-2 mb-2">Add a filter</p>

                        <v-btn fab color="primary" small @click="add(0)">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </template>

        <template v-else>
            <div class="filters">
                <v-card class="background pa-2" :class="[model.length > 1 && index !== model.length - 1 ? 'mb-4' : '']" flat v-for="(filter, index) in model" :key="index">
                    <app-table-filter-row v-model="model[index]" :items="items" :fields="fields" :key="cache"></app-table-filter-row>

                    <v-row class="filter-row">
                        <v-col class="filter-col" cols="12" align="right">
                            <v-btn icon small color="dark-grey" @click="remove(index)">
                                <v-icon small>mdi-delete-outline</v-icon>
                            </v-btn>
                            <v-btn icon small color="dark-grey" @click="duplicate(index)">
                                <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                            </v-btn>
                            <v-btn icon small color="dark-grey" @click="add(index)">
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import AppTableFilterRow from "@/components/table/app-table-filter-row.vue";

export default {
    name: "app-table-filter",

    props: {
        value: {
            type: Array,
        },
        form: {
            type: Object,
        },
        columns: {
            type: Array,
        },
        items: {
            type: Array,
        },
    },

    components: {
        AppTableFilterRow,
    },

    data() {
        return {
            cache: 0,
            fields: [],
            model: this.value,
        };
    },

    methods: {
        mapFields(field) {
            if (!field) {
                return;
            }

            // Run code here
            if (field.type !== "group" && field.directive !== "sub-object" && field.directive !== "embedded" && !field.disableFilter && field.type !== "action-cell") {
                let { title, text, value, key, type, isArray } = field;

                this.fields.push({
                    title: text || title,
                    key: value || key,
                    type,
                    isArray,
                });
            }

            (field.fields || []).forEach(this.mapFields);

            return;
        },
        remove(index) {
            this.model.splice(index, 1);

            this.cache++;
        },
        duplicate(index) {
            this.model.splice(index + 1, 0, {
                field: this.model[index].field,
                operator: this.model[index].operator,
                value: this.model[index].value,
            });

            this.cache++;
        },
        add(index) {
            this.model.splice(index + 1, 0, {
                field: {},
                operator: {},
                value: null,
            });

            this.cache++;
        },
    },

    created() {
        let form = this.form;
        let columns = this.columns;
        let fields = columns.concat(form.fields);

        (fields || []).forEach(this.mapFields);

        // this.$emit('input', this.model);
    },
};
</script>

<style lang="scss">
.app-table-filters {
    .filter-row {
        margin: -4px;

        .filter-col {
            padding: 4px;
        }
    }

    .field {
        .v-input__control {
            .v-input__slot {
                background: var(--v-light-base) !important;
            }
        }
    }

    .v-select__selections {
        overflow: scroll;
        flex-wrap: nowrap;

        span {
            flex-shrink: 0;

            &.v-chip {
                border-radius: 4px !important;
                pointer-events: none;
            }
        }
    }
}
</style>
