<template>
    <div>
        <template v-if="value && value.length">
            <span class="no-wrap small--text" :style="colorStyles">{{ value }}</span>
        </template>

        <template v-else-if="value > 0">
            <span class="no-wrap small--text" :style="colorStyles">{{ value }}</span>
        </template>

        <template v-else-if="value && value.score">
            <span class="no-wrap small--text" :style="colorStyles">{{ value.score }}</span>
        </template>

        <template v-else>
            <span class="dark-grey--text">—</span>
        </template>
    </div>
</template>

<script>
// Packages
import _ from "lodash";

export default {
    name: "score-cell",

    props: {
        item: [Object, String],
        header: Object,
    },

    computed: {
        value() {
            let value = this.item;
            let header = this.header;

            if (header.parent) value = value[header.parent];
            value = value[header.value];

            return value;
        },
        colorStyles() {
            let styles = {};

            let value = this.value;

            if (value == "Abstained") {
                styles.color = this.$vuetify.theme.themes.light.error;
            }

            if (value.submitted) {
                styles.color = this.$vuetify?.theme?.themes?.light?.success || "#198754";
            } else if (value.score) {
                styles.color = this.$vuetify.theme.themes.light.warning || "#bb2124";
            }

            return styles;
        },
    },
};
</script>

<style lang="scss"></style>
