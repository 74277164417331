<template>
    <v-row class="filter-row">
        <v-col class="filter-col" cols="12" sm="6">
            <v-autocomplete v-model="model.field" class="field ma-0" hide-details dense filled solo flat :items="fields" item-text="title" item-value="key" placeholder="Field" return-object></v-autocomplete>
        </v-col>

        <v-col class="filter-col" cols="12" sm="6">
            <v-autocomplete v-model="model.operator" class="field ma-0" hide-details dense filled solo flat :items="operators" item-text="title" item-value="key" placeholder="Operator" return-object> </v-autocomplete>
        </v-col>

        <template v-if="type == 'date-cell' || type == 'count-down-cell'">
            <v-col class="filter-col" cols="12">
                <date-picker :key="operator" :range="operator == 'btw' || operator == 'nbtw'" class="field" v-model="model.value" :selected="options" hide-details></date-picker>
            </v-col>
        </template>

        <template v-if="type == 'score-cell'">
            <v-col class="filter-col" cols="12">
                <!-- <date-picker :key="operator" :range="operator == 'btw' || operator == 'nbtw'" class="field" v-model="model.value" :selected="options" hide-details></date-picker> -->
                <v-text-field type="number" pattern="\d*" inputmode="decimal" class="field" v-model="model.value" hide-details dense filled solo flat placeholder="Type a number"></v-text-field>
            </v-col>
        </template>

        <template v-else>
            <v-col class="filter-col" cols="12">
                <v-autocomplete v-model="model.value" class="field ma-0" hide-details dense filled solo flat :multiple="multiple(model)" :items="filtered(model)" item-text="title" item-value="key" placeholder="Value" return-object>
                    <template v-slot:selection="{ item, index }">
                        <template v-if="type == 'status' || type == 'object'">
                            <div class="status-cell mr-2">
                                <span class="inline-tag" :class="[item.key, item.style ? item.style : '']"
                                    ><span class="p-relative">{{ (item && item.title) || item }}</span></span
                                >
                            </div>
                        </template>

                        <template v-else>
                            <span>{{ (item && item.title) || item }}<span class="mr-1" v-if="multiple(model) && model.value.length && model.value.length > 1 && index !== model.value.length - 1">,</span></span>
                        </template>
                    </template>

                    <!-- <template v-slot:item="{ item }" v-if="model.field.type == 'status'">
              <div class="status-cell mr-2">
                  <span class="inline-tag" :class="[item && item.key ? item.key : '']">{{item && item.title || item}}</span>
              </div>
          </template> -->
                </v-autocomplete>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import DatePicker from "@/components/form-field/date-picker.vue";

import _ from "lodash";

export default {
    name: "app-table-filter-row",

    props: {
        value: {
            type: Object,
        },
        items: {
            type: Array,
        },
        fields: {
            type: Array,
        },
    },

    components: {
        DatePicker,
    },

    data() {
        return {
            model: this.value,
            status: [
                {
                    title: "Active",
                    key: "active",
                    color: "success",
                },
                {
                    title: "Draft",
                    key: "draft",
                    color: "warning",
                },
                {
                    title: "Archived",
                    key: "archived",
                    color: "dark-grey",
                },
                // {
                //     title: "Incomplete",
                //     key: "incomplete",
                //     color: "dark-grey",
                //     style: "solid",
                // },
                // {
                //     title: "Pending",
                //     key: "pending",
                //     color: "info",
                // },
                // {
                //     title: "Rejected",
                //     key: "rejected",
                //     color: "error",
                // },
                // {
                //     title: "Expired",
                //     key: "expired",
                //     color: "error",
                // },
            ],
            options: {
                minimum: 0,
                maximum: 1,
            },
        };
    },

    computed: {
        field() {
            return this.model && this.model.field;
        },
        type() {
            return this.model && this.model.field && this.model.field.type;
        },
        operator() {
            return this.model && this.model.operator && this.model.operator.key;
        },
        operators() {
            let operators = [];

            if (!this.field.isArray) {
                operators.push({
                    title: "Is equal to",
                    key: "eq",
                });

                operators.push({
                    title: "Is not equal to",
                    key: "ne",
                });
            }

            if (this.type == "date-cell" || this.type == "count-down-cell") {
                operators.push({
                    title: "Is before",
                    key: "b",
                });

                operators.push({
                    title: "Is not before",
                    key: "nb",
                });

                operators.push({
                    title: "Is after",
                    key: "a",
                });

                operators.push({
                    title: "Is not after",
                    key: "na",
                });

                operators.push({
                    title: "Is between",
                    key: "btw",
                    multiple: true,
                });

                operators.push({
                    title: "Is not between",
                    key: "nbtw",
                    multiple: true,
                });
            }

            if (this.type == "score-cell") {
                operators.push({
                    title: "Is greater than",
                    key: "gt",
                });

                operators.push({
                    title: "Is less than",
                    key: "lt",
                });
            } else {
                operators.push({
                    title: "Is one of",
                    key: "in",
                    multiple: true,
                });

                operators.push({
                    title: "Is not one of",
                    key: "nin",
                    multiple: true,
                });
            }

            return operators;
        },
    },

    methods: {
        filtered(model) {
            let items = [];
            let { field } = model;

            switch (field.type) {
                case "status-cell":
                    items = this.status;

                    break;
                case "date-cell":
                    items = this.items
                        .map((item) => {
                            let parent = item && item.data && item.data[field.key];
                            if (parent) return (parent && parent.title) || parent || null;

                            return (item && item[field.key] && item[field.key].title) || (item && item[field.key]) || null;
                        })
                        .filter((item) => item != null);

                    break;
                case "tag-cell":
                case "score-cell":
                case "text-cell":
                    if (field.isArray) {
                        this.items.forEach((item) => {
                            let array = item[field.key];

                            let parent = item && item.data && item.data[field.key];
                            if (parent) array = item.data[field.key];

                            if (array && array.length) {
                                array.forEach((arrayItem) => {
                                    items.push(arrayItem.title || arrayItem);
                                });
                            }
                        });
                    } else {
                        items = this.items
                            .map((item) => {
                                let parent = item && item.data && item.data[field.key];
                                if (parent) return (parent && parent.title) || parent || null;

                                return (item && item[field.key] && item[field.key].title) || (item && item[field.key]) || null;
                            })
                            .filter((item) => item != null);
                    }
                    break;
                case "reference":
                    items = this.items
                        .map((item) => {
                            let parent = item && item.data && item.data[field.key];
                            if (parent) return (parent && parent.title) || parent || null;

                            return (item && item[field.key] && item[field.key].title) || (item && item[field.key]) || null;
                        })
                        .filter((item) => item != null);

                    break;
                case "boolean-cell":
                    items = this.items.map((item) => item[field.key].title || item[field.key]);

                    break;
                case "object-cell":
                    if (field.isArray) {
                        this.items.forEach((item) => {
                            let array = item[field.key];

                            if (array && array.length) {
                                array.forEach((arrayItem) => {
                                    items.push(arrayItem.title);
                                });
                            }
                        });
                    } else {
                        items = this.items.map((item) => (item && item[field.key] && item[field.key].title) || null).filter((item) => item != null);
                    }

                    break;
                case "image-cell":
                    if (field.isArray) {
                        this.items.forEach((item) => {
                            let array = item[field.key];

                            let parent = item && item.data && item.data[field.key];
                            if (parent) array = item.data[field.key];

                            if (array && array.length) {
                                array.forEach((arrayItem) => {
                                    items.push(arrayItem.title);
                                });
                            }
                        });
                    } else {
                        items = this.items
                            .map((item) => {
                                let parent = item && item.data && item.data[field.key];
                                if (parent) return (parent && parent.title) || null;

                                return (item && item[field.key] && item[field.key].title) || null;
                            })
                            .filter((item) => item != null);
                    }

                    break;
                // case 'array':
                //     this.items.forEach(item => {
                //         let array = item[field.key];
                //
                //         if (array && array.length) {
                //             array.forEach(arrayItem => {
                //                 items.push(arrayItem.title);
                //             });
                //         }
                //     });
                //
                //     break;
                default:
                    items = this.items.map((item) => (item && item[field.key] && item[field.key].title) || null).filter((item) => item != null);
            }

            return items;
        },
        multiple(model) {
            let { operator } = model;

            if (operator.multiple) {
                return true;
            }

            return false;
        },
        isArray(value) {
            return _.isArray(value);
        },
    },

    watch: {
        "model.field": function (v) {
            if (v && v.key) {
                this.model.value = null;
            }
        },
        "model.operator": function (v) {
            if (v && v.key) {
                this.model.value = null;
            }
        },
        // "model.value": function(v) {
        //     if (v && v.key) {
        //         this.$emit('input', v);
        //     }
        // }
    },
};
</script>

<style lang="scss">
.app-table-filter-row {
    display: flex;
    align-items: flex-start;
    height: 100%;

    // .comparator {
    //     .comparator-divider {
    //         width: 2px;
    //         height: 100%;
    //         background: var(--v-primary-base);
    //     }
    //
    //     .comparator-name {
    //         background: var(--v-primary-base);
    //         text-transform: uppercase;
    //         color: #FFFFFF;
    //         border-radius: 4px;
    //         height: 20px;
    //         // padding: 1px 3px;
    //     }
    // }
}
</style>
