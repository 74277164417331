<template>
    <div>
        <template v-if="isArray">
            <div class="stacked-images">
                <div
                    class="images-outer rounded-lg"
                    v-for="(item, index) in value"
                    :key="index"
                    :style="imageClass(index)"
                >
                    <v-img
                        class="rounded-lg elevation-4 grey scale-on-hover"
                        :src="$fluro.asset.imageUrl(item)"
                        width="35"
                        height="35"
                    >
                        <template v-slot:placeholder>
                            <v-row class="fill-height grey ma-0" align="center" justify="center">
                                <v-progress-circular
                                    :width="1"
                                    :size="14"
                                    color="dark"
                                    indeterminate
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </div>
            </div>
        </template>

        <template v-else-if="value">
            <v-img class="rounded-lg elevation-4 grey" :src="$fluro.asset.imageUrl(value)" width="35" height="35">
                <template v-slot:placeholder>
                    <v-row class="fill-height grey ma-0" align="center" justify="center">
                        <v-progress-circular :width="1" :size="14" color="dark" indeterminate></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
        </template>

        <template v-else>
            <v-card class="rounded-lg elevation-4 grey d-flex align-center justify-center" width="35" height="35">
                <v-icon color="dark-grey" small>mdi-image-outline</v-icon>
            </v-card>
        </template>
    </div>
</template>

<script>
// Packages
import _ from "lodash";

export default {
    name: "image-cell",

    props: {
        item: [Object, String],
        header: Object,
    },

    computed: {
        value() {
            let value = this.item;
            let header = this.header;
            if (header.parent) value = value[header.parent];
            value = value[header.value];

            return value;
        },
        isArray() {
            return _.isArray(this.value);
        },
    },

    methods: {
        imageClass(index) {
            let styles = {};

            if (this.value.length > 1 && index != 0) {
                styles.transform = `translateX(calc(-20px * ${index}))`;
            }

            return styles;
        },
    },
};
</script>

<style lang="scss">
.stacked-images {
    display: flex;
    align-items: center;

    .images-outer {
        background: var(--v-grey-base);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid var(--v-light-base);
        margin-left: -3px;
        pointer-events: none;
    }
}
</style>
