<template>
    <div class="status-cell">
        <template v-if="isArray">
            <span class="inline-tag mr-2" v-for="(item, index) in value" :key="index" :content="item" v-if="value && value.length">{{ item }}</span>
        </template>

        <template v-else>
            <span class="inline-tag" :content="value">{{ value }}</span>
        </template>
    </div>
</template>

<script>
// Packages
import _ from "lodash";
import moment from "moment";

export default {
    name: "object-cell",

    props: {
        item: [Object, String],
        header: Object,
    },

    computed: {
        value() {
            let value = this.item;
            let header = this.header;

            if (header.parent) value = value[header.parent];
            value = value[header.value];

            return moment(value).format("MMM D, YYYY");
        },
        isArray() {
            return _.isArray(this.value);
        },
    },
};
</script>

<style lang="scss"></style>
