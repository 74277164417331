<template>
    <div>
        <template v-if="!value">
            <v-icon small color="dark-grey">mdi-close</v-icon>
        </template>
        <template v-else>
            <v-icon small color="success">mdi-check-circle-outline</v-icon>
        </template>
    </div>
</template>

<script>
export default {
    name: "boolean-cell",

    props: {
        item: [Object, String],
        header: Object,
    },

    computed: {
        value() {
            let value = this.item;
            let header = this.header;
            if (header.parent) value = value[header.parent];
            value = value[header.value];

            return value;
        },
    },
};
</script>

<style lang="scss"></style>
