<template>
    <div class="app-table overflow-hidden full-width full-height d-flex flex-column">
        <div class="mb-6 d-flex align-center justify-space-between flex-grow-1" v-if="!hideActions && !hideTitle">
            <h3 v-if="!hideTitle && title">{{ title }}</h3>

            <div
                class="action-buttons d-flex align-center"
                :class="{ 'full-width px-5': hideTitle || !title }"
                v-if="!hideActions"
            >
                <v-text-field
                    ref="search"
                    class="field"
                    dense
                    hide-details
                    filled
                    solo
                    flat
                    prepend-inner-icon="mdi-magnify"
                    v-model="search"
                    label=""
                    placeholder="Search..."
                    type="text"
                >
                    <template v-slot:append>
                        <span class="styled-button">
                            <v-icon x-small class="mr-1">mdi-apple-keyboard-command</v-icon>K
                        </span>
                    </template>
                </v-text-field>

                <v-menu
                    class="zindex-max"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    bottom
                    :key="loading"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ml-3" color="primary" small :text="!showFilters" v-bind="attrs" v-on="on">
                            <span class="filter-number" :class="{ active: showFilters }" v-if="filters.length"
                                ><span>{{ filters.length }}</span></span
                            >
                            <span>Filters</span>
                        </v-btn>
                    </template>

                    <v-card width="300" class="pa-3 light elevation-0 full-width overflow-hidden">
                        <app-table-filter
                            ref="filters"
                            v-model="filters"
                            :items="items"
                            :form="form"
                            :columns="headers"
                        ></app-table-filter>
                    </v-card>
                </v-menu>

                <!-- <v-btn class="ml-3" color="primary" small :text="!showFilters" @click="showFilters = !showFilters" v-if="form">
                    <span class="filter-number" :class="{ active: showFilters }" v-if="filters.length"
                        ><span>{{ filters.length }}</span></span
                    >
                    <span>Filters</span>
                </v-btn> -->

                <div v-if="buttons.length" v-for="(button, index) in buttons" :key="index">
                    <v-btn class="ml-3" :color="button.color" small @click="buttonClick(button)">
                        <v-icon class="mr-2" v-if="button.icon">{{ button.icon }}</v-icon>
                        {{ button.text }}
                    </v-btn>
                </div>

                <v-menu offset-y transition="slide-y-transition" content-class="zindex-11" v-if="menu && menu.length">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ml-3 elevation-2" color="dark" x-small fab v-bind="attrs" v-on="on">
                            <v-icon small color="light">mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <template v-for="(item, index) in menu">
                            <template v-if="item.divider">
                                <v-divider :key="index"></v-divider>
                            </template>

                            <template v-else>
                                <v-list-item :disabled="item.disabled" link @click="buttonClick(item)" :key="index">
                                    <v-list-item-icon>
                                        <v-icon color="dark" :disabled="item.disabled">{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </template>
                        </template>
                    </v-list>
                </v-menu>
            </div>
        </div>

        <div class="d-flex flex-column align-start full-width flex-shrink-1 overflow-hidden">
            <v-card class="light elevation-0 full-height full-width d-flex flex-column overflow-hidden">
                <v-data-table
                    ref="dataTable"
                    v-model="selected"
                    :loading="loading"
                    :show-select="showSelect"
                    class="box-shadow main-table full-height-table overflow-hidden"
                    item-key="_id"
                    :headers="headers"
                    :items="filtered"
                    :items-per-page="15"
                    @click:row="clicked"
                    @current-items="getFiltered"
                >
                    <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
                        <component
                            :is="header.type"
                            :item="item"
                            :header="header"
                            :key="item._id"
                            @function="runFunction"
                        ></component>
                    </template>
                </v-data-table>
            </v-card>

            <!-- <v-card width="500" class="pa-3 ml-6 light elevation-0 full-width overflow-hidden" v-if="showFilters">
                <app-table-filter v-model="filters" :items="items" :form="form" :columns="headers"></app-table-filter>
            </v-card> -->
        </div>
    </div>
</template>

<script>
// Components
import TextCell from "@/components/table/cells/text-cell.vue";
import ImageCell from "@/components/table/cells/image-cell.vue";
import ObjectCell from "@/components/table/cells/object-cell.vue";
import BooleanCell from "@/components/table/cells/boolean-cell.vue";
import DateCell from "@/components/table/cells/date-cell.vue";
import ActionCell from "@/components/table/cells/action-cell.vue";
import StatusCell from "@/components/table/cells/status-cell.vue";
import ScoreCell from "@/components/table/cells/score-cell.vue";
import TagCell from "@/components/table/cells/tag-cell.vue";

import AppTableFilter from "@/components/table/app-table-filter.vue";

// Services
import Filters from "@/modules/filters";

import moment from "moment";

export default {
    name: "app-table",

    components: {
        TextCell,
        ImageCell,
        ObjectCell,
        BooleanCell,
        DateCell,
        ActionCell,
        StatusCell,
        ScoreCell,
        TagCell,
        AppTableFilter,
    },

    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        title: String,
        items: Array,
        headers: Array,
        buttons: Array,
        menu: Array,
        form: Object,
        hideActions: Boolean,
        hideTitle: Boolean,
        showSelect: Boolean,
        clickSelect: Boolean,
        loading: Boolean,
        singleSelect: {
            type: Boolean,
            default() {
                return false;
            },
        },
        itemsPerPage: [Number, String],
    },

    data() {
        return {
            showFilters: false,
            search: "",
            filters: [],
            selected: this.value,
        };
    },

    computed: {
        filtered() {
            let items = this.items;
            let filters = this.filters;
            if (this.search?.length) {
                let keywords = this.search.toLowerCase();

                // keywords = keywords.split(/[ ,]+/);

                items = items.filter((item) => {
                    return this.headers.some((header) => {
                        let value = item;

                        if (header.parent) value = item[header.parent];
                        value = value[header.value];
                        if (value || this.isBoolean(value)) {
                            switch (header.type) {
                                case "number":
                                    value = value.toString();
                                    value = value.toLowerCase();
                                    return value.includes(keywords);

                                case "date":
                                    value = moment(value).format("dddd Do MMMM YYYY");
                                    value = value.toLowerCase();
                                    return value.includes(keywords);

                                case "boolean":
                                    if (value) return keywords == "true" || keywords == "yes";
                                    return keywords == "false" || keywords == "no";

                                default:
                                    if (this.isObject(value)) {
                                        let values = Object.values(value);

                                        return values.some((item) => {
                                            if (!this.isObject(item) && !this.isArray(item)) {
                                                item = item.toString();
                                                item = item.toLowerCase();
                                                return item.includes(keywords);
                                            }
                                        });
                                    }

                                    if (this.isArray(value)) {
                                        return value.some((item) => {
                                            // let values = Object.values(item);

                                            // return values.some((item) => {
                                            //     if (!this.isObject(item) && !this.isArray(item)) {
                                            item = item.toString();
                                            item = item.toLowerCase();
                                            return item.includes(keywords);
                                            //     }
                                            // });
                                        });
                                    }

                                    value = value.toString();
                                    value = value.toLowerCase();
                                    return value.includes(keywords);
                                // return keywords.every((keyword) => value.includes(keyword));
                            }
                        }
                    });
                });
            }

            if (filters && filters.length) {
                items = Filters.filter(items, filters);
            }

            return items;
        },
    },

    methods: {
        runFunction(button, item) {
            this.$emit(button, item);
        },
        buttonClick(button) {
            this.$emit(button.function);
        },
        handleKeydown(e) {
            if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode == 75) {
                e.preventDefault();
                this.$refs.search.focus();
            }
        },
        getFiltered(e) {
            this.$emit("filtered", this?.$refs?.dataTable?.$children?.[0]?.filteredItems || []);
        },
        clicked(row) {
            if (this.clickSelect) {
                let index = this.selected.findIndex((item) => item._id == row._id);

                if (index < 0) {
                    //New modifier to allow only 1 selection in the table, used for scribe selection
                    if (this.singleSelect && this.selected.length) {
                        console.log("ROW", row, this.selected);
                    } else {
                        this.selected.push(row);
                    }
                } else {
                    this.selected.splice(index, 1);
                }
            } else {
                this.$emit("clicked", row);
            }
        },
        isBoolean(value) {
            return typeof value === "boolean" && value !== null;
        },
        isObject(value) {
            return value && typeof value === "object" && value !== null && !this.isArray(value);
        },
        isArray(value) {
            return Array.isArray(value);
        },
    },

    watch: {
        selected(v) {
            this.$emit("input", v);
        },
    },

    created() {
        document.addEventListener("keydown", this.handleKeydown, false);
    },

    destroyed() {
        document.removeEventListener("keydown", this.handleKeydown, false);
    },
};
</script>

<style lang="scss">
.app-table {
    .filter-number {
        background: var(--v-primary-base);
        width: 20px;
        height: 20px;
        border-radius: 100%;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;

        &.active {
            color: var(--v-primary-base);
            background: #ffffff;
        }
    }

    .v-data-table__checkbox {
        .v-input--selection-controls__input {
            margin: 0 !important;
        }
    }

    thead {
        tr {
            th {
                background: var(--v-light-base) !important;

                &:first-child {
                    position: sticky;
                    left: 0;
                    background: var(--v-light-base) !important;
                    z-index: 12 !important;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                &:first-child {
                    position: sticky;
                    left: 0;
                    background: var(--v-light-base) !important;
                    z-index: 11 !important;
                }
            }
        }
    }
}
</style>
