<template>
    <div class="status-cell">
        <template v-if="value">
            <span class="inline-tag text-uppercase" :class="value">
                <b>{{ value }}</b>
            </span>
        </template>

        <template v-else>
            <v-icon color="grey">—</v-icon>
        </template>
    </div>
</template>

<script>
// Packages
import _ from "lodash";

export default {
    name: "status-cell",

    props: {
        item: [Object, String],
        header: Object,
    },

    computed: {
        value() {
            let value = this.item;
            let header = this.header;

            if (header.parent) value = value[header.parent];
            value = value[header.value];

            return value;
        },
        isArray() {
            return _.isArray(this.value);
        },
    },
};
</script>

<style lang="scss"></style>
