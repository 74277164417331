<template>
    <div class="d-flex align-center status-cell">
        <template v-if="loading">
            <v-icon color="grey">—</v-icon>
        </template>

        <template v-else-if="modified && modified.length">
            <span class="inline-tag mr-2" v-for="(item, index) in capped" :key="index" :content="item" v-if="value && value.length">{{ item.title }}</span>
            <span class="inline-tag dark light--text" v-if="value.length > capped.length">+{{ extra }} more</span>
        </template>

        <template v-else-if="isArray && capped && capped.length">
            <span class="inline-tag mr-2" v-for="(item, index) in capped" :key="index" :content="item" v-if="value && value.length">{{ item.title }}</span>
            <span class="inline-tag dark light--text" v-if="value.length > capped.length">+{{ extra }} more</span>
        </template>

        <template v-else-if="value && value._id">
            <span class="inline-tag" :content="value">{{ value.title }}</span>
        </template>

        <template v-else>
            <v-icon color="grey">—</v-icon>
        </template>
    </div>
</template>

<script>
// Packages
import _ from "lodash";

export default {
    name: "object-cell",

    props: {
        item: [Object, String],
        header: Object,
    },

    data() {
        return {
            modified: [],
            loading: false,
        };
    },

    computed: {
        value() {
            let value = this.item;
            let header = this.header;

            if (header.parent) value = value[header.parent];
            value = value[header.value];

            return value;
        },
        capped() {
            let value = this.value;

            if (this.modified?.length) value = this.modified;

            if (value?.length > 2) {
                value = value.slice(0, 2);
            }

            return value;
        },
        extra() {
            let value = this.value.length;
            let capped = this.capped.length;

            return value - capped;
        },
        isArray() {
            return _.isArray(this.value);
        },
    },

    methods: {
        async populate() {
            let ids = this.value;

            if (ids?.length) {
                this.modified = await this.$fluro.api.post("/content/multiple", { ids }).then(({ data }) => data);
            }
        },
    },

    async mounted() {
        if (this.header.populate) {
            this.loading = true;
            await this.populate();
            this.loading = false;
        }
    },
};
</script>

<style lang="scss"></style>
