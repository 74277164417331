<template>
    <div>
        <template v-if="isArray">
            <span v-for="(item, index) in value" :key="index" :content="item" v-if="value && value.length">{{ item.title || item }}<span v-if="index != value.length - 1">,&nbsp;</span></span>
        </template>

        <template v-else-if="value && value._id">
            <span class="no-wrap small--text">{{ value.title }}</span>
        </template>

        <template v-else-if="value && value.length">
            <span class="no-wrap small--text">{{ value }}</span>
        </template>

        <template v-else-if="value > 0">
            <!-- <v-icon color="primary" v-html="`mdi-numeric-${value}-box`"></v-icon> -->
            <span class="no-wrap small--text">{{ value }}</span>
        </template>

        <template v-else>
            <span class="dark-grey--text">—</span>
        </template>
    </div>
</template>

<script>
// Packages
import _ from "lodash";

export default {
    name: "text-cell",

    props: {
        item: [Object, String],
        header: Object,
    },

    computed: {
        value() {
            let value = this.item;
            let header = this.header;

            if (header.parent) value = value[header.parent];
            value = value[header.value];

            return value;
        },
        isArray() {
            return _.isArray(this.value);
        },
    },
};
</script>

<style lang="scss"></style>
