import _ from "lodash";
import moment from "moment";

let service = {
    filter(items, filters) {
        let filtered = items;

        filtered = filtered.filter((item) => {
            let matches = [];

            filters.forEach((filter) => {
                let { field, operator, value } = filter;

                let val;

                // Setup the selected value
                if (_.isString(value) || _.isBoolean(value) || _.isNumber(value) || _.isObject(value)) val = value && value.title ? String(value && value.title).toLowerCase() : String(value).toLowerCase();
                if (_.isArray(value)) val = value.map((item) => (item && item.key ? String(item.key).toLowerCase() : String(item).toLowerCase()));

                if (field.key && operator.key && val && val.length) {
                    // Race issue. when field or operator changes, value is set to null but only after it has already emitted the field or operator
                    if ((operator.multiple && _.isString(value)) || (!operator.multiple && _.isArray(value))) return;

                    let key = item[field.key];

                    let parent = item && item.data && item.data[field.key];
                    if (parent) key = item.data[field.key];

                    // Setup the possible matching values
                    let text = _.isString(key) || _.isNumber(key) || _.isBoolean(key) ? String(key)?.toLowerCase() : null;
                    let obj = _.isObject(key) && key?.title ? String(key.title).toLowerCase() : _.isObject(key) && key?.score ? String(key.score) : null;
                    let arr = _.isArray(key) && key?.length ? key.map((arrayItem) => (arrayItem?.title ? String(arrayItem?.title).toLowerCase() : String(arrayItem).toLowerCase())) : null;

                    // if (_.isObject(key)) {
                    //     console.log(key);
                    //     console.log(obj);
                    // }

                    let start = moment(val[0]);
                    let end = moment(val[1]);
                    let dateObj = moment(val);
                    let dateCompare = moment(text);

                    switch (operator.key) {
                        case "eq":
                            // Equal
                            if (val === text || val === obj) matches.push(true);
                            break;

                        case "ne":
                            // Not equal
                            if (val !== text && val !== obj) matches.push(true);
                            break;

                        case "b":
                            // Before
                            if (dateObj > dateCompare) matches.push(true);
                            break;

                        case "nb":
                            // Not before
                            if (dateObj < dateCompare) matches.push(true);
                            break;

                        case "a":
                            // After
                            if (dateObj < dateCompare) matches.push(true);
                            break;

                        case "na":
                            // Not after
                            if (dateObj > dateCompare) matches.push(true);
                            break;

                        case "btw":
                            // Between
                            if (dateCompare >= start && dateCompare <= end) matches.push(true);
                            break;

                        case "nbtw":
                            // Not between
                            if (dateCompare < start || dateCompare > end) matches.push(true);
                            break;

                        case "lt":
                            // Less than
                            text = Number(text);
                            obj = Number(obj);
                            val = Number(val);

                            if ((text !== 0 && text < val) || (obj !== 0 && obj < val)) matches.push(true);
                            break;

                        case "gt":
                            // Greater than
                            text = Number(text);
                            obj = Number(obj);
                            val = Number(val);

                            if ((text !== 0 && text > val) || (obj !== 0 && obj > val)) matches.push(true);
                            break;

                        case "in":
                            // One of
                            if (val.includes(text) || val.includes(obj) || val.find((valItem) => arr && arr.length && arr.includes(valItem))) matches.push(true);
                            break;

                        case "nin":
                            // Not one of
                            if (!val.includes(text) && !val.includes(obj) && !val.find((valItem) => arr && arr.length && arr.includes(valItem))) matches.push(true);
                            break;
                        default:
                    }
                } else {
                    matches.push(true);
                }
            });

            if (matches.length == filters.length) return item;
        });

        return filtered;
    },
};

export default service;
